import React from 'react';
import { Heading, Box, Text, Link } from 'rebass';

const LandingPage = () => (
  <Box mt={[4, 5, 6]} mx={[3, 4, 6]} color="font">
    <Heading
      textAlign="left"
      as="h1"
      fontSize={[5, 5, 5]}
      mb={[2, 3]}
      sx={{
        // color: '#edaf35',
        fontWeight: 'bold',
        display: 'inline-block',
      }}
    >
      Hi, I'm Shivain
    </Heading>

    <Heading as="h2" fontSize={[4, 4, 4]} mb={[4, 5]} textAlign="left">
      I'm a software engineer currently working in Toronto, Canada
    </Heading>

    <Text as="p" fontSize={[3, 3]} mb={[3]}>
      You can email me at{' '}
      <Link href="mailto: shivain@me.com" target="_blank" rel="noopener">
        shivain@me.com
      </Link>
    </Text>

    <Text as="p" fontSize={[3, 3]} mb={[3]}>
      Or message me on{' '}
      <Link href="https://www.linkedin.com/in/shivainthapar/" target="_blank" rel="noopener">
        LinkedIn
      </Link>
    </Text>

    <Text as="p" fontSize={[3, 3]} mb={[3]}>
      You can view some of my older projects and code on{' '}
      <Link href="https://github.com/sharpar" target="_blank" rel="noopener">
        Github
      </Link>
    </Text>

    <Text as="p" fontSize={[3]} mb={3}>
      Or listen to me complain about basketball on{' '}
      <Link href="https://twitter.com/shivainthapar" target="_blank" rel="noopener">
        Twitter
      </Link>
    </Text>
  </Box>
);
export default LandingPage;
